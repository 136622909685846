<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <div
        class="el-form-text"
        v-if="typeof value !== 'undefined'"
    >
      <el-tag
          :type="!value ? 'success' : 'danger'"
          effect="dark"
          size="small"
      >
        {{ value ? $t('system.yes') : $t('system.no') }}
      </el-tag>
    </div>
    <div
        class="el-form-text"
        v-else
    >
      <span class="no-date-title">{{ $t('system.no_data') }}</span>
    </div>
  </base-form-item>
</template>

<script>
import BaseFormItem from '../../UI/FormItem.vue';
import abstractForm from '../../mixin/index';

export default {
  name: 'index',
  mixins: [abstractForm],
  components: {BaseFormItem},
};
</script>

<style scoped>

</style>
