<template>
  <div class="generator--form-item">
    <!--    <template v-if="field && field.front_properties">-->
    <template>
      <el-form-item
          :class="[field.front_properties && field.front_properties.label && field.front_properties.label.position ? 'el-form--labelPosition-' + field.front_properties.label.position : 'el-form--labelPosition-top']"
          :label-width="field.front_properties && field.front_properties.label && field.front_properties.label.width ? field.front_properties.label.width + 'px' : ''"
          :required="!!(field.validation && field.validation.includes('required'))"
          :size="field.front_properties && field.front_properties.label && field.front_properties.label.size ? field.front_properties.label.size : ''"
      >
        <template
            class="test"
            slot="label"
        >
          <Tooltip
              :content="field.front_properties.label.tooltip ? $t(field.front_properties.label.tooltip).toUpperCase() : ''"
              :labelTooltip="field.front_properties.label.tooltip"
              v-if="field.front_properties && field.front_properties.label && field.front_properties.label.tooltip"
          />
          <span
              :title="field.front_properties && field.front_properties.label.name ? $t(field.front_properties.label.name).toUpperCase() : ''"
              class="labelWrap"
              v-else
          >{{
              field.front_properties && field.front_properties.label.name ? $t(field.front_properties.label.name).toUpperCase() : ''
           }}</span>
        </template>
        <div class="px-2">
          <slot />
        </div>
      </el-form-item>
    </template>
    <!--      <template v-else>-->
    <!--        <el-form-item :label-width="field.label && field.label.labelWidth ? field.label.labelWidth + 'px' : ''"-->
    <!--                      :size="field.meta && field.meta.size ? field.meta.size : ''"-->
    <!--                      :class="[field.label && field.label.labelPosition ? 'el-form&#45;&#45;labelPosition-' + field.label.labelPosition : 'el-form&#45;&#45;labelPosition-top']"-->
    <!--                      :required="field.meta && field.meta.required ? field.meta.required : false">-->
    <!--          <template slot="label">-->
    <!--            <Tooltip v-if="field.label && field.label.labelTooltip"-->
    <!--                     :labelTooltip="field.label.labelTooltip"-->
    <!--                     :content="field.label && field.label.name ? $t(field.label.name).toUpperCase() : ''"/>-->
    <!--            <span v-else-->
    <!--                  class="labelWrap">{{field.label && field.label.name ? $t(field.label.name).toUpperCase() : ''}}</span>-->
    <!--          </template>-->
    <!--          <div class="px-2">-->
    <!--            <slot/>-->
    <!--          </div>-->
    <!--        </el-form-item>-->
    <!--      </template>-->
    <hr
        class="separator"
        v-if="field.separator"
    />
    <!--    </template>-->
  </div>
</template>

<script>

export default {
  name: 'FormItem',
  props: ['field', 'schemaVersion'],
  components: {'Tooltip': () => import('./Tooltip.vue')},
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.generator--form-item {
  .el-form-item {
    margin: 5px 0;

    .el-form-item__content {
      .lineHeight--initial {
        line-height: initial;
      }

      /*line-height: initial;*/
      display: inline-block;
      margin: 0 !important;
      width: 100%;
    }
  }
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.separator {
  border: 0.5px solid rgb(221, 225, 228);
  margin: 0 -15px;
}
</style>
