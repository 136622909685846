export default {
  props: [
    'field',
    'data',
    'dictionaries',
    'schemaVersion',
    'backend_errors',
    'inputRequiredEmpty',
    'mode',
  ],
  computed: {
    dictionaryName() {
      return this.field.dictionary && this.field.dictionary.name ? this.field.dictionary.name : null;
    },
    value: {
      get() {
        if (this.data && this.field.dbName) {
          if (typeof this.data.base[this.field.dbName] !== 'undefined') {
            return this.data.base[this.field.dbName];
          } else if (typeof this.data.custom[this.field.dbName] !== 'undefined') {
            return this.data.custom[this.field.dbName];
          }
        }
        return null;
      },
      set(newVal) {
        if (this.data.base && (this.field.dbName in this.data.base)) {
          this.data.base = {...this.data.base, [this.field.dbName]: newVal};
        } else if (this.data.custom && (this.field.dbName in this.data.custom)) {
          this.data.custom = {...this.data.custom, [this.field.dbName]: newVal};
        }
      },
    },
    meta() {
      return this.field.front_properties ? this.field.front_properties : null;
    },
  },
};
